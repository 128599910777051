import { useStaticQuery, graphql } from 'gatsby'

const useBest10InternationalMetadata = () => {
  const query = useStaticQuery(graphql`
    query best10InternationalMetadata {
      bannerBG: file(
        relativePath: { eq: "best-10-international/banner/bg.jpg" }
      ) {
        id
        ...Best10InternationalImageOptimize
      }
      bannerImg: file(
        relativePath: { eq: "best-10-international/banner/img.png" }
      ) {
        id
        ...Best10InternationalImageOptimize
      }

      listBG: file(relativePath: { eq: "best-10-international/list/bg.jpg" }) {
        id
        ...Best10InternationalImageOptimize
      }
      listRemaxLaptop: file(
        relativePath: { eq: "best-10-international/list/remax-laptop.png" }
      ) {
        id
        ...Best10InternationalImageOptimize
      }
      listRemaxTablet: file(
        relativePath: { eq: "best-10-international/list/remax-tablet.png" }
      ) {
        id
        ...Best10InternationalImageOptimize
      }
      listRemaxPhone: file(
        relativePath: { eq: "best-10-international/list/remax-phone.png" }
      ) {
        id
        ...Best10InternationalImageOptimize
      }
      listPropertyLaptop: file(
        relativePath: { eq: "best-10-international/list/property-laptop.png" }
      ) {
        id
        ...Best10InternationalImageOptimize
      }
      listPropertyTablet: file(
        relativePath: { eq: "best-10-international/list/property-tablet.png" }
      ) {
        id
        ...Best10InternationalImageOptimize
      }
      listPropertyPhone: file(
        relativePath: { eq: "best-10-international/list/property-phone.png" }
      ) {
        id
        ...Best10InternationalImageOptimize
      }
      listProspectaLaptop: file(
        relativePath: { eq: "best-10-international/list/prospecta-laptop.png" }
      ) {
        id
        ...Best10InternationalImageOptimize
      }
      listProspectaTablet: file(
        relativePath: { eq: "best-10-international/list/prospecta-tablet.png" }
      ) {
        id
        ...Best10InternationalImageOptimize
      }
      listProspectaPhone: file(
        relativePath: { eq: "best-10-international/list/prospecta-phone.png" }
      ) {
        id
        ...Best10InternationalImageOptimize
      }
      listLiLaptop: file(
        relativePath: { eq: "best-10-international/list/li-laptop.png" }
      ) {
        id
        ...Best10InternationalImageOptimize
      }
      listLiTablet: file(
        relativePath: { eq: "best-10-international/list/li-tablet.png" }
      ) {
        id
        ...Best10InternationalImageOptimize
      }
      listLiPhone: file(
        relativePath: { eq: "best-10-international/list/li-phone.png" }
      ) {
        id
        ...Best10InternationalImageOptimize
      }
      listPacificoLaptop: file(
        relativePath: { eq: "best-10-international/list/pacifico-laptop.png" }
      ) {
        id
        ...Best10InternationalImageOptimize
      }
      listPacificoTablet: file(
        relativePath: { eq: "best-10-international/list/pacifico-tablet.png" }
      ) {
        id
        ...Best10InternationalImageOptimize
      }
      listPacificoPhone: file(
        relativePath: { eq: "best-10-international/list/pacifico-phone.png" }
      ) {
        id
        ...Best10InternationalImageOptimize
      }
      listSeahorseLaptop: file(
        relativePath: { eq: "best-10-international/list/seahorse-laptop.png" }
      ) {
        id
        ...Best10InternationalImageOptimize
      }
      listSeahorseTablet: file(
        relativePath: { eq: "best-10-international/list/seahorse-tablet.png" }
      ) {
        id
        ...Best10InternationalImageOptimize
      }
      listSeahorsePhone: file(
        relativePath: { eq: "best-10-international/list/seahorse-phone.png" }
      ) {
        id
        ...Best10InternationalImageOptimize
      }
      listAndrewLaptop: file(
        relativePath: { eq: "best-10-international/list/andrew-laptop.png" }
      ) {
        id
        ...Best10InternationalImageOptimize
      }
      listAndrewTablet: file(
        relativePath: { eq: "best-10-international/list/andrew-tablet.png" }
      ) {
        id
        ...Best10InternationalImageOptimize
      }
      listAndrewPhone: file(
        relativePath: { eq: "best-10-international/list/andrew-phone.png" }
      ) {
        id
        ...Best10InternationalImageOptimize
      }
      listMyriamLaptop: file(
        relativePath: { eq: "best-10-international/list/myriam-laptop.png" }
      ) {
        id
        ...Best10InternationalImageOptimize
      }
      listMyriamTablet: file(
        relativePath: { eq: "best-10-international/list/myriam-tablet.png" }
      ) {
        id
        ...Best10InternationalImageOptimize
      }
      listMyriamPhone: file(
        relativePath: { eq: "best-10-international/list/myriam-phone.png" }
      ) {
        id
        ...Best10InternationalImageOptimize
      }
      listCherylLaptop: file(
        relativePath: { eq: "best-10-international/list/cheryl-laptop.png" }
      ) {
        id
        ...Best10InternationalImageOptimize
      }
      listCherylTablet: file(
        relativePath: { eq: "best-10-international/list/cheryl-tablet.png" }
      ) {
        id
        ...Best10InternationalImageOptimize
      }
      listCherylPhone: file(
        relativePath: { eq: "best-10-international/list/cheryl-phone.png" }
      ) {
        id
        ...Best10InternationalImageOptimize
      }
      listRedLaptop: file(
        relativePath: { eq: "best-10-international/list/red-laptop.png" }
      ) {
        id
        ...Best10InternationalImageOptimize
      }
      listRedTablet: file(
        relativePath: { eq: "best-10-international/list/red-tablet.png" }
      ) {
        id
        ...Best10InternationalImageOptimize
      }
      listRedPhone: file(
        relativePath: { eq: "best-10-international/list/red-phone.png" }
      ) {
        id
        ...Best10InternationalImageOptimize
      }

      listRedPhone: file(
        relativePath: { eq: "best-10-international/list/red-phone.png" }
      ) {
        id
        ...Best10InternationalImageOptimize
      }

      countryBahamas: file(
        relativePath: { eq: "best-10-international/countries/bahamas.jpg" }
      ) {
        id
        ...Best10InternationalImageOptimize
      }
      countryBelize: file(
        relativePath: { eq: "best-10-international/countries/belize.jpg" }
      ) {
        id
        ...Best10InternationalImageOptimize
      }
      countryCanada: file(
        relativePath: { eq: "best-10-international/countries/canada.jpg" }
      ) {
        id
        ...Best10InternationalImageOptimize
      }
      countryCyprus: file(
        relativePath: { eq: "best-10-international/countries/cyprus.jpg" }
      ) {
        id
        ...Best10InternationalImageOptimize
      }
      countryMexico: file(
        relativePath: { eq: "best-10-international/countries/mexico.jpg" }
      ) {
        id
        ...Best10InternationalImageOptimize
      }
      countryPanama: file(
        relativePath: { eq: "best-10-international/countries/panama.jpg" }
      ) {
        id
        ...Best10InternationalImageOptimize
      }
      countrySpain: file(
        relativePath: { eq: "best-10-international/countries/spain.jpg" }
      ) {
        id
        ...Best10InternationalImageOptimize
      }
      countryUK: file(
        relativePath: { eq: "best-10-international/countries/uk.jpg" }
      ) {
        id
        ...Best10InternationalImageOptimize
      }
      lcfBGPhone: file(
        relativePath: { eq: "best-10-international/others/lcf-bg-phone.jpg" }
      ) {
        id
        ...Best10InternationalImageOptimize
      }
    }

    fragment Best10InternationalImageOptimize on File {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, quality: 90, formats: [AUTO])
      }
    }
  `)

  return query
}

export default useBest10InternationalMetadata
