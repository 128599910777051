import React from 'react'
import { getImage } from 'gatsby-plugin-image'

import LayoutInnerPage from '@src/layouts/InnerPage'
import Seo from '@components/seo/Index'
import RealEstateBanner from '@components/global/banner/RealEstateBanner'
import Best10RealEstate from '@components/global/gallery/Best10RealEstate'
import LeadContactForm from '@components/global/forms/LeadContactForm/Index'

import {
  pageData as page,
  bannerData,
  listData,
} from '@src/data/Best10International'
import useBest10InternationalMetadata from '@hooks/best-10-international-metadata'
import useWindowSize from '@hooks/useWindowSize'
import { extractGatsbyImageDataRelativePath as extractImage } from '@utils/imageFile'
import * as cx from './10BestInternational.module.scss'

const Best10InternationalWebsites = () => {
  const {
    bannerBG,
    bannerImg,
    listBG,
    listRemaxLaptop,
    listRemaxTablet,
    listRemaxPhone,
    listPropertyLaptop,
    listPropertyTablet,
    listPropertyPhone,
    listProspectaLaptop,
    listProspectaTablet,
    listProspectaPhone,
    listLiLaptop,
    listLiTablet,
    listLiPhone,
    listPacificoLaptop,
    listPacificoTablet,
    listPacificoPhone,
    listSeahorseLaptop,
    listSeahorseTablet,
    listSeahorsePhone,
    listAndrewLaptop,
    listAndrewTablet,
    listAndrewPhone,
    listMyriamLaptop,
    listMyriamTablet,
    listMyriamPhone,
    listCherylLaptop,
    listCherylTablet,
    listCherylPhone,
    listRedLaptop,
    listRedTablet,
    listRedPhone,
    lcfBGPhone,
    countryBahamas,
    countryBelize,
    countryCanada,
    countryCyprus,
    countryMexico,
    countryPanama,
    countrySpain,
    countryUK,
  } = useBest10InternationalMetadata()

  const imgListData = [
    {
      laptop: listRemaxLaptop,
      tablet: listRemaxTablet,
      phone: listRemaxPhone,
      identifier: countryBelize,
    },
    {
      laptop: listPropertyLaptop,
      tablet: listPropertyTablet,
      phone: listPropertyPhone,
      identifier: countryUK,
    },
    {
      laptop: listProspectaLaptop,
      tablet: listProspectaTablet,
      phone: listProspectaPhone,
      identifier: countryCyprus,
    },
    {
      laptop: listLiLaptop,
      tablet: listLiTablet,
      phone: listLiPhone,
      identifier: countryCanada,
    },
    {
      laptop: listPacificoLaptop,
      tablet: listPacificoTablet,
      phone: listPacificoPhone,
      identifier: countryMexico,
    },
    {
      laptop: listSeahorseLaptop,
      tablet: listSeahorseTablet,
      phone: listSeahorsePhone,
      identifier: countryBahamas,
    },
    {
      laptop: listAndrewLaptop,
      tablet: listAndrewTablet,
      phone: listAndrewPhone,
      identifier: countryMexico,
    },
    {
      laptop: listMyriamLaptop,
      tablet: listMyriamTablet,
      phone: listMyriamPhone,
      identifier: countrySpain,
    },
    {
      laptop: listCherylLaptop,
      tablet: listCherylTablet,
      phone: listCherylPhone,
      identifier: countryCanada,
    },
    {
      laptop: listRedLaptop,
      tablet: listRedTablet,
      phone: listRedPhone,
      identifier: countryPanama,
    },
  ]
  const isPhone = useWindowSize().width <= 767

  return (
    <LayoutInnerPage
      hasWhiteTransparentNav
      noFloatingShareButtons
      wrapperClassName={cx.wrapper}
    >
      <Seo title={page.title} uri={page.uri} />
      <RealEstateBanner
        wrapperClassName={cx.banner}
        breadcrumbs={page.breadcrumbs}
        heading={bannerData.heading.text}
        subheading={bannerData.subheading.text}
        bg={extractImage(bannerBG)}
        img={extractImage(bannerImg)}
      />
      <Best10RealEstate
        hasIdentifier
        bg={extractImage(listBG)}
        wrapperClassName={cx.list}
        heading={listData.heading.text}
        subheading={listData.subheading.text}
        buttonText={listData.list.buttonText}
        items={listData.list.items}
        imgItems={imgListData}
      />
      <LeadContactForm
        version={page.lcf.version}
        modelOrBackground={page.lcf.model}
        background={isPhone && getImage(lcfBGPhone)}
        title={page.lcf.title}
        subtitle={page.lcf.subtitle}
        submitLabel={page.lcf.submitLabel}
        leadName={page.lcf.leadName}
        layout={page.lcf.layout}
      />
    </LayoutInnerPage>
  )
}

export default Best10InternationalWebsites
