import React from 'react'

export const pageData = {
  title: '10 Best International Real Estate Websites',
  breadcrumbs: [
    { text: 'Home', url: '/' },
    {
      text: 'Real Estate Websites',
      url: '/real-estate-websites/',
    },
    {
      text: '10 Best International Real Estate Websites',
      url: '/real-estate-websites/10-best-international-real-estate-websites/',
    },
  ],
  uri: '/real-estate-websites/10-best-international-real-estate-websites/',
  lcf: {
    version: 'LCF03',
    model: 'Elisse',
    title: 'Free Consultation',
    subtitle: 'Get In Touch With Us For A',
    submitLabel: 'I want a stunning website!',
    leadName: 'Consultation: 10 Best International Real Estate Websites',
    layout: 'B',
  },
}

export const bannerData = {
  heading: {
    text: '10 Best International Real Estate Websites',
    className: 'heading-5',
  },
  subheading: {
    text: 'Effective Real Estate Marketing for a Global Audience',
    className: 'subtitle-7',
  },
}

export const listData = {
  heading: {
    text: 'World Class Website Design',
    className: 'heading-5',
  },
  subheading: {
    text: 'Powerful branding and lead generation',
    className: 'subtitle-7',
  },
  list: {
    titleClassName: 'heading-3',
    descriptionClassName: 'default-body large',
    buttonText: 'GET PRICING & FEATURES',
    items: [
      {
        title: 'RE/MAX Island Real Estate',
        description:
          'RE/MAX’s instantly recognizable red, white and blue is used to great effect on RE/MAX Island Real Estate’s BelizeRealEstateSearch.com alongside breathtaking images of Belize’s tropical beauty. These, along with resources such as detailed community pages, property listings, guides, and answers to FAQs, work together to highlight the team’s local expertise and international reach.',
        identifier: 'Belize',
      },
      {
        title: 'Property Experts',
        description:
          'On PropertyExperts.co.uk, a dynamic homepage video introducing the Property Experts team and their area of expertise (London and the Home Counties) immediately captures one’s attention. The overall impression of exceptional professionalism that it conveys is underscored by the site’s smooth animation effects, intuitive layout, and sophisticated colour scheme of green and gold balanced with black and white.',
        identifier: 'UK',
      },
      {
        title: 'Prospecta Development',
        description:
          'For over 40 years, Prospecta Development has specialized in building beautiful homes in some of Cyprus’ most scenic locales. So we made sure the developer’s website reflects its skill in marrying structure and nature. The site has a decidedly “architectural” feel, thanks to clean lines, geometric shapes, generous white space, and the use of marble-like textures in the background. The blue-and-gold color scheme also connotes the prestige that comes with buying a home lovingly and skillfully built by Prospecta. A section<br/> highlighting its offices in Shanghai, Beijing, Chengdu, and Mumbai likewise demonstrates this esteemed developer’s global expertise.',
        identifier: 'Cyprus',
      },
      {
        title: 'Li & Zahedi Real Estate Group',
        description:
          'Reflecting their team’s multicultural character and expertise, LZGroup.com stands out with its bilingual content and bold red-and-black color scheme. The site’s dynamic grid layout provides an accommodating user experience. By presenting Vancouver real estate resources in a way that is organized and comprehensible to people from both English and Chinese speaking backgrounds, the Li & Zahedi Real Estate Group also offer a glimpse of their effective marketing strategies and keen understanding of design.',
        identifier: 'Canada',
      },
      {
        title: 'Pacifico Property',
        description:
          'PacificoProperty.com recreates the feeling of freedom and ease that comes with the beautiful coastal towns of Riviera Nayarit, Mexico. The turquoise and gray palette is pleasant to the eye, complementing the tropical hues in the photos of beachfront properties. The elegant layout makes strategic use of white space and minimalist elements, thus providing a user-friendly website with comprehensive community pages, lifestyle blogs, IDX features, and exhaustive Mexican real estate guides.',
        identifier: 'Mexico',
      },
      {
        title: 'Seahorse Realty',
        description:
          'Exuma and stunning beaches go hand in hand, which is why SeahorseRealtyExuma.com prominently features stunning shores on its banner. Naturally, we went for a blue-and-white color palette to reflect the breezy lifestyle that defines this island paradise. Featured areas, highlighted listings, and advanced property search filters also make finding your dream home a cinch.',
        identifier: 'Bahamas',
      },
      {
        title: 'Andrew Lemke',
        description:
          'A page-wide slideshow of stunning waterfront properties in Los Cabos dominates the homepage of AndrewLemke.com, whetting visitors’ appetite for more. A Quick Search widget above the fold allows convenient browsing while creating opportunities for lead captures. The clean, minimalist design and captivating photos direct viewers’ attention to the site’s Featured Properties and the rest of the content. More lead generation tools below the fold encourage visitors to provide contact information in exchange for access to valuable information.',
        identifier: 'Mexico',
      },
      {
        title: 'Myriam De Roye',
        description:
          'Passion and sophistication — two words that accurately describe Spain. They’re also apt descriptors for MDRLuxuryHomes.com, the premier resource for high-end properties in the Costa del Sol region. Bathed in hues of gold, red, and dark gray, this website exudes distinction and a zest for the good life. Tasteful use of animations and the parallax effect lend the site an energetic feel and reflect Myriam De Roye’s deep love for this sun-kissed slice of Andalusia.',
        identifier: 'Spain',
      },
      {
        title: 'Cheryl Thompson',
        description:
          'With its crisp and elegant style, TorontosFinestHomes.com is a joy to navigate. The classic black and white color scheme makes<br/> it easy to focus on the key sections of the website, including Featured Listings, Neighborhoods, and a Video of the Month, among others. The site makes sure it contains all the helpful features and local real estate information visitors need so they won’t have to search elsewhere for material related to Toronto luxury real estate. What really makes Cheryl’s site so exceptional is the<br/> seamless flow within and between web pages, thanks to its contemporary design.',
        identifier: 'Canada',
      },
      {
        title: 'Red Frog Beach',
        description:
          'To visit RedFrogProperty.com is to be whisked away to the scenic shores of Bocas del Toro in Panama. The full-screen banner video serves as a preview of the lush tropical forests, pristine beaches, and stunning homes that await you in this island paradise. A section on green initiatives clearly communicates Red Frog Beach’s dedication to nature conservation as well. All in all, this website serves as the premier source of information for resort-like living in Panama.',
        identifier: 'Panama',
      },
    ],
  },
}
